<template>
  <va-card
    class="investments-widget"
    :title="$t('dashboard.investment')"
  >
    <va-inner-loading :loading="loading">
      <div class="row">
        <div class="flex xs12">
          <h6>{{ $t('dashboard.current_investment', { current: currentPeriod, investment: currentInvestment }) }}</h6>
        </div>
        <div class="flex xs12">
          <h6>{{ $t('dashboard.total_invested', { invested: totalInvested }) }}</h6>
        </div>
        <div
          class="flex xs12"
          v-if="investments.length > 0"
        >
          <h6>Top 10</h6>
          <bar-chart :remote-data="investments" />
        </div>
      </div>
    </va-inner-loading>
  </va-card>
</template>

<script>
import { mapGetters } from 'vuex'
const BarChart = () => import(/* webpackPrefetch: true */ '../statistics/charts/BarChart')

export default {
  name: 'dashboard-investment-bars',
  components: { BarChart },
  data () {
    return {
      loading: false,
      error: false,
      totalInvested: 0,
      currentInvestment: 0,
      currentPeriod: '',
      investments: [],
    }
  },
  computed: {
    ...mapGetters(['dashboardPeriod']),
  },
  watch: {
    dashboardPeriod (val) {
      if (val) {
        this.getInvestmentData(val)
        this.currentPeriod = this.getPeriod(val, 10)
      }
    },
  },
  created () {
    this.getInvestmentData()
    this.currentPeriod = this.getPeriod()
  },
  methods: {
    getPeriod (year, month) {
      const date = new Date()
      let yearRef = Number(year)
      if (!year) {
        yearRef = date.getFullYear()
        month = date.getMonth()
      }
      let yearBefore = yearRef

      if (month < 9) {
        yearBefore -= 1
      } else {
        yearRef += 1
      }

      return yearBefore + '-' + yearRef
    },
    async getInvestmentData (year) {
      this.loading = true
      this.error = false
      let d = null

      let query = ''
      if (year) {
        query += `?year=${year}`
      }
      try {
        d = await this.$http.get(`assigments/numbers${query}`)
      } catch (e) {
        this.error = true
        return
      }

      let investments = d.data.data.countries || []
      investments = investments.sort((a, b) => b.count - a.count)
      for (const i of investments) {
        i.name = this.$t(i.name)
      }
      this.investments = investments.slice(0, 10)
      this.totalInvested = d.data.data.total || 0
      this.currentInvestment = d.data.data.current || 0
      this.loading = false
    },
  },
}
</script>

<style>
</style>
